.apply-mobile-buttom{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: none;
  padding: 1rem 2rem;
  background-color: cl(white);
  z-index: 3;
  text-align: center;
  box-shadow: rem(5px 0 10px rgba(0,0,0,0.2));
  @include media-breakpoint-down(md) {
    display: block;

  }
  .btn{
    min-width: rem(250px);
    @include media-breakpoint-down(xs) {
     width: 100%;
      min-width: unset;
    }
  }
}
.footer {

  position: relative;
  z-index: 2;
  color:cl(white);
  font-size: rem(15px);
  line-height: rem(24px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 22vw;
  @include media-breakpoint-down(sm) {
    padding-top: 20rem;

  }
  a{
    color: cl(white);
  }
  &-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media-breakpoint-down(xs) {
      justify-content: center;
      margin-bottom: 1rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(30px);
      color:cl(white);
      margin-left: rem(20px);
      border:rem(1px solid transpatenr);
      @include media-breakpoint-down(xs) {
        @include circle(40px);
        margin: 0 1rem;
      }
      &:hover {
        text-decoration: none;
        border:rem(1px solid cl(white));
      }
      i {
        font-size: rem(16px);
        @include media-breakpoint-down(xs) {
          font-size: rem(24px);
        }
      }
    }
  }
  &-logo {
    display: block;
    width:rem(420px);
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      width: rem(300px);
    }
    @include media-breakpoint-down(sm) {
      width: rem(250px);
    }

    @include media-breakpoint-down(xs) {
      margin: 0 auto 2rem;
    }

    img {
      width:100%;
      height: auto;
    }
  }
  address{
    margin-bottom: 1rem;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0.5rem;
    }
  }
  &-contacts {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    font-size: rem(15px);
    line-height: rem(25px);
    margin-bottom: 0;
    @include media-breakpoint-down(xs) {
      font-size: rem(16px);
      text-align: center;
      align-items: center;
    }
    @media (max-width: 500px){
      justify-content: center;
    }
    dt {
      color:cl(primary);
      font-size: rem(16px);
      line-height: rem(26px);
      width:rem(30px);
    }
    dd {
      margin: 0;
      a {
        color:cl(white);
      }
    }
  }
  &-copy {
    font-size: rem(14px);
    font-family:$font;
    @include media-breakpoint-down(xs) {
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  }
  &--top-line{
    padding: rem(39px 0);
    background-color: cl(primary,0.8);
    &-inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(xs) {
       flex-wrap: wrap;
      }
      .first-col{
        width: 64%;
        flex-shrink: 0;

        @include media-breakpoint-down(sm) {
          width: 60%;
        }
        @include media-breakpoint-down(xs) {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 2rem;
          align-items: center;
        }

        .link{
          @include media-breakpoint-down(xs) {
            margin-bottom: 1rem;
            display: block;
          }
        }
        .logo-col{
          width: rem(440px);
          @include media-breakpoint-down(lg) {
            width: rem(320px);
          }
          @include media-breakpoint-down(sm) {
            width: rem(270px);
          }

          @include media-breakpoint-down(xs) {
            width: 100%;
          }

        }
      }
      .second-col{
        width:36%;
        display: flex;
        flex-shrink: 0;
        align-self: flex-start;

        justify-content: flex-end;
        @include media-breakpoint-down(sm) {
          width: 40%;
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
          justify-content: center;
        }
      }
    }
    .email{
      margin-bottom: rem(10px);
    }
    .out-link{

      margin-left: rem(35px);
      @include media-breakpoint-down(md) {
        margin-left: 1rem;
      }
      @include media-breakpoint-down(sm) {
        margin-left: 1rem;
      }
      @media (max-width: 500px){
        margin: 0 1rem;
      }
      img{
        width: 100%;
        max-width: rem(130px);
        height: rem(90px);
        object-fit: contain;
        @include media-breakpoint-down(lg) {
          max-width: rem(90px);
          height: rem(60px);
        }
      }

    }
    ul{
    list-style: none;
         }
  }
  &--bottom-line{
    padding: rem(6px 15px);
    background-color: #9f9f9f;
    color: cl(white);
    @include media-breakpoint-down(md) {
      padding-bottom: 5.5rem;
    }
    img{
      height:rem(23px);
    }
  }
}

