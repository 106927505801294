.content-flex{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row-reverse;
  font-size: rem(18px);
  line-height: rem(28px);
  padding: rem(15px 0 50px 0);
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  .left-menu{
    flex-shrink: 0;
    width: 18.75rem;
    margin-right: 3rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right:0;
      margin-bottom: 2rem;
    }

    ul{
      border-radius: rem(px);
      background-color:cl(primary);
      box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      position: relative;
      width: 100%;
      list-style: none;
      margin-bottom: 1rem;
      padding: rem(13px 0);
      min-height: rem(200px);
      @include media-breakpoint-down(md) {
        display: none;
        min-height: unset;
      }
      li{
        color: cl(white);
        font-size: rem(18px);
        background-color: transparent;
        line-height: rem(24px);
        padding: rem(8px 20px 8px 24px);
        &.active{
          background-color:cl(secondary);

        }
        &:hover{
          background-color:cl(secondary,0.6);
        }
        a{
          color: cl(white);
          display: block;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }

  }
  .main-part{
    //width: calc(100% - 16.75rem);
    flex-grow: 1;
    width: auto;
    font-size: rem(18px);
    color: cl(text);
    line-height: rem(28px);
    @include media-breakpoint-down(md) {
      width: 100%;
      order: 1;
    }
    .frame-wrap{
      margin-top: -3rem;
      max-width: 90vw;
      height: 670px!important;
      @include media-breakpoint-down(md) {
        height: 1000px!important;
      }
    }
    p{
      margin-bottom: rem(20px);
    }
    //a{
    //  color: cl(secondary);
    //}

    .more-text{
      img{
        max-width: 80%;
        max-height: 70vh;
        object-fit: contain;
        display: block;
        margin: 0 auto 1.5rem;
        object-position: center top;
      }
      ul{
        list-style: none;
        padding-left: 2rem;
        li{
          position: relative;
          padding: rem(5px 0 5px 25px);
          &:before{
            position: absolute;
            content: "";
            border-radius: 50%;
            width: 3px;
            height: 3px;
            background-color: cl(primary);
            left: 0;
            top: 1.2rem;
          }
        }
      }
      .video-holder{
        width: 85%;
        margin: 0 auto 2rem;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        video{
          width: 100%;
          object-fit: cover;
          box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        }
      }
      &.news-inner{
        a{
          color: cl(primary);
        }
        .btns-wrap{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .btn{
            margin-right: 3rem;
            margin-top: 2rem;
            @include media-breakpoint-down(sm) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
    .first-block{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1.5rem;
      justify-content: space-between;
        .left-side{
          width: calc(100% - 28.125rem);
          padding-right:rem(50px);
          @include media-breakpoint-down(md) {
            width: 100%;
            padding-right: unset;
            order: 1;
          }
        }
        .image-side{
          width: rem(450px);
          @include media-breakpoint-down(md) {
            width: 100%;
            padding-right: unset;

          }
          .image-holder{
            width: rem(450px);
            height: rem(280px);
            position: relative;
            float: right;
            overflow: hidden;
            @include media-breakpoint-down(md) {
              float: none;
              margin: 0 auto 2rem;
            }
            @include media-breakpoint-down(xs) {
              width: 90vw;
              height:53vw;
            }
            .logo{
              opacity: 0.18;
              width: 45%;
              top: 0;
              left: 8%;
              position: absolute;
              z-index: 0;
            }
            .letters{
              position: absolute;
              left: 0;
              top: 18%;
              width: 35.5%;
              z-index: 1;
            }
            .text-block{
              border: 1px solid cl(secondary);
              box-shadow: -0.707px 0.707px 16px 0px rgba(0, 0, 0, 0.09);
              border-radius: rem(8px);
              background-color: rgb(255, 255, 255);
              min-height: rem(70px);
              padding: rem(11px 21px);
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: rem(18px);
              line-height: rem(25px);
              font-weight: 700;
              text-align: center;
              @extend .ttu;
              min-width: rem(130px);
              min-width: 32%;
              @include media-breakpoint-down(lg) {
                font-size: rem(15px);
                line-height: rem(22px);
              }
              @include media-breakpoint-down(xs) {
                font-size: 3.7vw;
                font-size: 2.5vw;
                min-height: 14vw;
              }
              &-1{
                right: 0;
                top:5%;
                z-index: 2;
              }
              &-2{
                left: 40%;
                top:27%;
                z-index: 3;
              }
              &-3{
                right: 0;
                top:48%;
                z-index: 2;
              }
              &-4{
                left: 40%;
                top:69%;
                z-index: 3;
              }
            }
          }

      }
    }

    .files-block{
      max-width: 100%;
      margin: 0 auto 2rem;
      .item-download-file{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(214, 214, 214);
        padding: rem(13px 23px);
        background-color: cl(white);
        margin-bottom: rem(12px);
        transition: 0.2s ease-in-out;
        font-size: rem(16px);
        font-family: $font;
        line-height: rem(25px);
        border-radius: rem(4px);
        @include media-breakpoint-down(md) {
          font-size: rem(16px);
          line-height: rem(26px);
          padding: rem(12px 15px);
        }
        &:hover{
          border: 1px solid cl(primary);
          text-decoration: none;
          color: cl(secondary);
          .name{
            color: cl(secondary);
          }
          .download-pic{
           color: cl(secondary);
          }
        }
        .text-cont{
          overflow-x: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          max-width: calc(100% - 3rem);
        }
        .file-img{
          height: rem(25px);

          margin: 0;
          margin-right: rem(25px);
          @include media-breakpoint-down(md) {
            height: rem(25px);
            margin-right: rem(15px);
          }
        }
        .name{
          color: cl(text);
          white-space: nowrap;
          transition: 0.2s ease-in-out;
          font-weight: 700;
        }
        .download-pic{
          position: relative;
          color: cl(primary);

        }
      }
    }
  }
}

.search-block{
  position: relative;
  margin:rem(0 0 0 0);
  width: rem(290px);
  height: rem(38px);
  @include media-breakpoint-down(xs) {
    order: 1;
    width: 100%;
    margin: 1rem 0;
  }
  .sbm{
    @extend .pos-centerY;
    border:0;
    position: absolute;
    width: rem(36px);
    height: rem(36px);
    background-color: transparent;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(0 10px);
    &:focus {
      outline: none;
      box-shadow:none;
      outline: dashed 1px rgb(214, 214, 214);
    }

    img{
      width: rem(20px);
      height: rem(20px);
      margin: 0;
    }
  }

  .form-control{
    display: block;
    width:100%;
    height: rem(38px);
    border-radius: rem(4px);
    background: cl(white);
    border:rem(1px solid rgb(214, 214, 214));
    padding:rem(0 16px);
    font-size:rem(15px);
    color:cl(text);
    @include placeholder( #bababa);
    &:focus {
      outline: none;
      box-shadow:none;
      background: cl(white);
      border-color:cl(primary);
    }
  }
}

.board-meetings-block{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: rem(20px);
  flex-wrap: wrap;
  .item{
      border-radius: rem(8px);
      background-color: rgb(255, 255, 255);
      box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      width: calc(50% - 1rem);
      font-size: rem(16px);
      line-height: rem(26px);
      color: cl(primary);
      padding: rem(32px 36px);
      overflow: hidden;
      margin-bottom: rem(20px);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
      a{
        color: cl(primary);
        word-break: break-all;
      }
      .date{
        font-size: rem(18px);
        line-height: rem(28px);
        font-weight: 700;
        color: cl(primary);
      }
    .address{
      font-size: rem(16px);
      line-height: rem(26px);
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .zoom{
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .m-id{
      color: cl(primary);
    }
  }
}

.tia-event-block{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: rem(20px);
  flex-wrap: wrap;
  .item{
    border-radius: rem(8px);
    background-color: rgb(255, 255, 255);
    box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
    width: calc(50% - 1rem);
    font-size: rem(16px);
    line-height: rem(26px);
    color: cl(primary);
    padding: rem(32px 36px);
    overflow: hidden;
    margin-bottom: rem(20px);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .event-name{
      color: cl(primary);
      word-break: break-all;
      font-weight: 700;
      font-size: rem(20px);
    }
    .date{
      font-size: rem(16x);
      line-height: rem(28px);
      font-weight: 400;
      color: cl(primary);
    }
    .address{
      font-size: rem(16px);
      line-height: rem(26px);
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .zoom{
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .m-id{
      color: cl(primary);
    }
  }
}

.tia-slider{
  width: 100%;
  .item{
    img{
      max-width: 100% !important;
      max-height: 100vh !important;
      object-fit: contain;
     margin:0 !important;
    }
  }
}
.table-wrap{
  .table{
    tr{
      border: 0;
      th{
        font-weight: 700;
        font-size: rem(16px);
        color: cl(white);
        background-color: cl(primary);
        padding: rem(6px 0 6px 25px);
        &:first-child{
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
        &:last-child{
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
      td{
        border: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: rem(12px 0 12px 25px);
        font-size: rem(16px);
        p{
          margin-bottom: 0;
          word-break: break-all;
        }
      }
    }
  }
}

.account-mobile-toggle{
  margin-bottom: 0.675rem;
  width: 100%;
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.float-more-btns{
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include media-breakpoint-down(md) {
    display: none;

  }
  a{
    background-color: cl(secondary);
    color: cl(white);
    @extend .ttu;
    padding:rem(10px);
    font-size: rem(16px);
    font-weight: 700;
    margin-bottom: 0.25rem;
    text-align: center;
    min-width: rem(142px);
    box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
    &:hover{
      background-color: cl(primary);
      text-decoration: none;
    }
    &:first-child{
      min-width: rem(153px);
    }
  }
}


.table{
  thead{
    th {
      background-color: cl(primary);
      color: cl(white);
      border: 0;
      font-size: rem(16px);
    }

    tr {
      border: 0;
    }
  }
  tbody {
    tr {
      td {
        min-width: rem(180px);
        font-size: rem(17px);
        border: 0;
        border-bottom: 1px solid #c9c9c9;
        .logo-icon{
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
    }
  }
}


.menu-page{
  .event-top-line{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      margin-bottom: 2rem;
    }
    .month-select-block{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 0;
      @include media-breakpoint-down(sm) {
        width: 50%;
        order: 1;
      }


      .month-select{
        margin-left: 1rem;
        a{
          color: cl(gray);
          &:hover{
            color: cl(primary);
          }
        }
      }
    }
    .fird{
      text-align: right;
      @include media-breakpoint-down(sm) {
        width: 50%;
        order: 1;

      }
    }

    ul{
      padding: 0!important;
      margin-bottom: 0;
      list-style: none;
      display: flex;
      align-items: center;
      li{
        padding: 0!important;
        font-weight: 700;
        font-size: rem(25px);
        &:before{
          display: none;
        }
        &:first-child{
          padding-right: 1rem!important;
        }
        &:last-child{
          padding-left: 1rem!important;
        }
        button{
          outline: none;
          border: 0;
          background-color: transparent;
          color: cl(primary);
        }
      }
    }
    h2{
      text-align: center;
      @include media-breakpoint-down(sm) {
        width: 100%;
        order: 0;
      }
    }
  }
}